<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    element-loading-text="Loading template..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="footer-action-block">
      <el-button
        v-if="!this.isExecute"
        icon="el-icon-edit"
        type="danger"
        class="btn button-center float-right"
        @click="goToEditTemplateData()"
        round
        >Edit Data</el-button
      >

      <el-button
        v-if="this.isExecute"
        class="submit-button btn button-center float-right"
        @click="save"
      >
        Save
        <i class="el-icon-right"></i>
      </el-button>

      <div class="required-fields-info">
        <el-badge :value="getRquiredFieldsCount()" class="item" type="primary">
          <div>Filled</div>
        </el-badge>
        <el-badge :value="getTotalFieldsCount()" class="item" type="warning">
          <div>Required Fields</div>
        </el-badge>
      </div>
    </div>

    <div class="template-body-section">
      <el-form>
        <div class="form-builder" ref="formBuilder" :style="getStylediv">
          <div
            v-if="fieldsData && fieldsData.length"
            class="form-fields-holder"
          >
            <div
              v-for="(field, index) of fieldsData"
              :key="index"
              :style="getStyle(field)"
            >
              <DateExecute
                :data="field"
                v-if="field.input_type === 'DATE'"
                :form="form"
                :is-view="!isExecute"
              ></DateExecute>
              <FileExecute
                :data="field"
                v-if="field.input_type === 'FILE'"
                :form="form"
              ></FileExecute>
              <MultiLineTextExecute
                :data="field"
                v-if="field.input_type === 'MULTI_LINE_TEXT'"
                :form="form"
                :is-view="!isExecute"
              ></MultiLineTextExecute>
              <SingleLineTextExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_TEXT'"
                :form="form"
                :is-view="!isExecute"
              ></SingleLineTextExecute>
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT'"
                :form="form"
                :is-view="!isExecute"
              ></MultiSelectExecute>
              <SelectExecute
                :data="field"
                v-if="field.input_type === 'SELECT'"
                :form="form"
                :is-view="!isExecute"
              ></SelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO'"
                :form="form"
                :is-view="!isExecute"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="field.input_type === 'NUMBER'"
                :form="form"
                :is-view="!isExecute"
              ></NumberExecute>
              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING'"
                :form="form"
                :is-view="!isExecute"
              ></HeadingExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX'"
                :form="form"
                :is-view="!isExecute"
              ></CheckBoxExecute>
              <ESignatureExecute
                :data="field"
                v-if="field.input_type === 'SIGNATURE'"
                :form="form"
              ></ESignatureExecute>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </section>
</template>

<script>
import DateExecute from "./formComponentsExecute/DateExecute";
import SingleLineTextExecute from "./formComponentsExecute/SingleLineTextExecute";
import MultiLineTextExecute from "./formComponentsExecute/MultiLineTextExecute";
import FileExecute from "./formComponentsExecute/FileExecute";
import MultiSelectExecute from "./formComponentsExecute/MultiSelectExecute";
import NumberExecute from "./formComponentsExecute/NumberExecute";
import SelectExecute from "./formComponentsExecute/SelectExecute";
import YesOrNoExecute from "./formComponentsExecute/YesOrNoExecute";
import HeadingExecute from "./formComponentsExecute/HeadingExecute";
import CheckBoxExecute from "./formComponentsExecute/CheckBoxExecute";
import ESignatureExecute from "./formComponentsExecute/ESignatureExecute";
import { bus } from "../../main";
import { mapGetters } from "vuex";

export default {
  name: "templates-PreViewTemplateForUserProfile",
  components: {
    DateExecute,
    FileExecute,
    MultiLineTextExecute,
    SingleLineTextExecute,
    MultiSelectExecute,
    NumberExecute,
    SelectExecute,
    YesOrNoExecute,
    HeadingExecute,
    CheckBoxExecute,
    ESignatureExecute,
  },

  props: ["templateData", "templateDataId", "isExecute"],
  data() {
    return {
      form: {},
      fieldsData: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      loading: false,
      templateFormData: {},
      templateCode: null,
      showForm: true,
      existedTemplateDataId: "",
      existedTemplateDataValues: {},

      fieldActions: [
        {
          name: "Single Line Text",
          inputType: "SINGLE_LINE_TEXT",

          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 400,
        },

        {
          name: "Multi Line Text",
          inputType: "MULTI_LINE_TEXT",
          min_height: 70,
          min_width: 200,

          max_width: 800,
          max_height: 700,
        },

        {
          name: "Select",
          inputType: "SELECT",
          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 400,
        },
        {
          name: "Multiple Select",
          inputType: "MULTI_SELECT",
          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 400,
        },
        {
          name: "Number",
          inputType: "NUMBER",

          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 400,
        },
        {
          name: "Date",
          inputType: "DATE",
          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 400,
        },
        {
          name: "Yes or No",
          inputType: "YES_OR_NO",
          min_height: 70,
          min_width: 200,

          max_height: 100,
          max_width: 500,
        },
        {
          name: "Checkbox",
          inputType: "CHECKBOX",
          min_height: 25,
          min_width: 25,

          max_height: 80,
          max_width: 80,
        },
        {
          name: "Image",
          inputType: "FILE",
          min_height: 90,
          max_height: 120,
          max_width: 500,
          min_width: 200,
        },
        {
          name: "Content",
          inputType: "HEADING",
          min_height: 50,
          min_width: 200,
          img_url: "Heading.svg",
        },
        {
          name: "Signature",
          inputType: "SIGNATURE",
          min_height: 90,
          max_height: 120,
          max_width: 300,
          min_width: 200,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getTemplateErrors",
    ]),

    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
    ]),

    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (inputType === "SINGLE_LINE_TEXT") {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (inputType === "NUMBER") {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },

    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },
    getStylediv() {
      return `height:${this.formHeight}px;`;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },
  },

  async mounted() {
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    await this.setTemplateInfo();

    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );
  },

  methods: {
    getDefaultSizeRangesByFieldType(inputType) {
      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },
    save() {
      if (this.templateDataId) {
        this.updateTemplateData();
      }
    },
    next() {
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      }
      bus.$emit("next-step");
    },

    goToEditTemplateData() {
      this.isExecute = true;
    },
    async updateTemplateData() {
      try {
        let params = {
          template_data: this.form,
          template_data_id: this.templateDataId,
        };
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );

        if (this.getTemplatesDataUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
          this.isExecute = false;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at updating data",
          });
        }
      } catch (err) {
        console.log(err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },

    getRquiredFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && this.form[e.key]
      ).length;
    },

    getTotalFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && !e.value
      ).length;
    },
    getStyle(item) {
      let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
      return style;
    },

    async setTemplateInfo() {
      this.loading = true;

      if (this.templateDataId && this.templateDataId.length) {
        await this.fetchUserTemplateData();
      } else if (
        !this.templateDataId &&
        (this.getNewWorkflowUser || this.workflowStepId)
      ) {
        await this.fetchUserExistedTemplateData();
      }
      await this.setTemplateElements(this.templateData);
      this.loading = false;
    },

    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.storedFormBuilderWidth = templateData.page_base_width;
      if (this.type == "STANDARD") {
        this.showFields = false;
      } else {
        let defaultSection = templateData.sections.find(
          (x) => x.type == "DEFAULT"
        ); // Todo need to change for multiple sections
        // this.fieldsData = defaultSection.fields;

        // we are sorting all fields depends on x,y cordinates to flow in the form
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          if (a.y - b.y < 10) {
            return a.x - b.x;
          }
          return a.y - b.y;
        });

        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );

        if (this.templateDataId && this.getTemplatesData) {
          this.showForm = false;
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.getTemplatesData.template_data
          );
        } else if (
          this.existedTemplateDataId &&
          this.existedTemplateDataValues
        ) {
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.existedTemplateDataValues
          );
        }

        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });

        let lastElement = defaultSection.fields[this.fieldsData.length - 1];

        this.formHeight = lastElement.y + lastElement.height + 200;
      }
    },

    setTemplateDataToFormFields(fieldsData, data) {
      return fieldsData.forEach((field) => {
        if (data && data[field.key]) {
          this.$set(this.form, field.key, data[field.key]);
        }
      });
    },

    async saveForm() {
      bus.$emit("finish", this.form);
    },
    async fetchUserTemplateData() {
      await this.$store.dispatch("templatesData/fetchUserTemplateData", {
        template_data_id: this.templateDataId,
      });
    },
    async fetchUserExistedTemplateData() {
      if (this.getNewWorkflowUser || this.getUserWorkflowData.user_id) {
        await this.$store.dispatch("templatesData/fetchUserTemplateData", {
          user_id: this.getNewWorkflowUser || this.getUserWorkflowData.user_id,
          template_id: this.templateData._id,
        });
        if (this.getTemplatesData && this.getTemplatesData.length) {
          this.existedTemplateDataId = this.getTemplatesData[0]._id;
          this.existedTemplateDataValues =
            this.getTemplatesData[0].template_data;
        }
      } else {
        this.existedTemplateDataId = null;
        this.existedTemplateDataValues = {};
      }
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
    "$route.params.workflow_data_id"(id) {
      this.workflowDataId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-template-view {
  margin: 1em 0em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    z-index: 100;
    h1 {
      font-size: 2.1em;

      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }
  .footer-action-block {
    position: relative;
    margin-bottom: 2em;
    .el-button {
      margin: 2em auto 0;
      display: block;
      color: #ffffff;
      padding: 0.5em 1em;
      min-width: 150px;
      font-size: 1em;
      border-radius: 30px !important;
    }
    .required-fields-info {
      top: 10px;
      font-size: 14px;
      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }
  .form-builder {
    position: relative;
    width: 950px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}
</style>
